import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ImageGrid from './ImageGrid';
import ImagePopup from './ImagePopup';
import About from './About';
import Home from './Home';
import Contact from './Contact';

function App() {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const imageModules = importAll(require.context('./images', false, /\.(png|jpe?g|svg|JPG)$/));

    const imagesData = imageModules.map((imageModule) => {
      const pathParts = imageModule.split('/');
      const fileName = pathParts[pathParts.length - 1];
      const [title, year, type, dimensions] = fileName.replace(/\.[^/.]+$/, "").split(',');
      const cleanedDimensions = dimensions.split(')')[0] + ')'; // Remove parts after the closing parenthesis

      // Extract width and height from dimensions
      const dimensionMatch = cleanedDimensions.match(/(\d+(\.\d+)?) x (\d+(\.\d+)?)/);
      const width = parseFloat(dimensionMatch[3]);
      const height = parseFloat(dimensionMatch[1]);

      return {
        src: imageModule,
        title: title.replace('\'', '').replace('\'', '').trim(),
        year: parseInt(year.trim(), 10),
        type: type.trim(),
        dimensions: cleanedDimensions.trim(),
        width: width,
        height: height,
      };
    });

    setImages(imagesData);
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  const timberlakeSunset = images.find(image => image.title === 'Timberlake Sunset');

  return (
    <Router>
      <div className="App" onContextMenu={(e) => e.preventDefault()}> {/* Disable right-click */}
        <div className="top-bar">
          <div className="logo">Seiji Yonehara</div>
          <nav className="nav-menu">
            <Link to="/">Home</Link>
            <Link to="/gallery">Gallery</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </div>
        <Routes>
          <Route path="/" element={timberlakeSunset && <Home image={timberlakeSunset} />} />
          <Route path="/gallery" element={
            <div className="image-grid-container">
              <ImageGrid images={images} onImageClick={handleImageClick} />
            </div>
          } />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {selectedImage && <ImagePopup image={selectedImage} onClose={closePopup} />}
      </div>
    </Router>
  );
}

export default App;